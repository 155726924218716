<template>
  <div class="page-view carnet">
    <!-- <router-link class="back-to" to="/">
      <v-icon name="fa-chevron-left" />Home
    </router-link> -->
    <Form
      class="relative"
      @submit="tryCarnet"
      v-if="!loading && !resetForms"
    >
      <div class="container mx-auto sm:flex sm:items-center sm:flex-wrap">
        <div class="item">
          <h1 class="page-title">—Validatore Carnet</h1>
          <h3 class="subtitle">Clicca sul codice per modificarlo manualmente.</h3>
        </div>
        <div class="item large sm:pl-10">
          <div class="carnet-wrapper">
            <div class="sm:flex sm:flex-wrap">
              <div class="item">
                <i>Codice carnet</i>
              </div>
              <div class="item large relative pl-8">
                <span class="hash">#</span>
                <Field
                  id="carnetInput"
                  name="carnet"
                  rules="required|min:7|max:7"
                  placeholder="XXXXXXX"
                  v-model="carnet"
                  @input="checkRegex"
                />
                <ErrorMessage name="carnet" />
              </div>
            </div>
          </div>
          <div class="search-operator-wrapper">
            <Field
              id="operatorInput"
              name="operator"
              rules="required|numeric|max:5"
              placeholder="Inserisci il codice operatore"
              v-model="operator"
            />
            <ErrorMessage class="text-red-600" name="operator" />
          </div>
        </div>
      </div>
      <div class="divider">
        <button type="submit" :class="{ disabled : carnet === null || operator === null }">
          Assegna Carnet
        </button>
      </div>
      <div class="overlay-ok" v-if="overlayOpen">
        <v-icon name="io-close" scale="1" fill="#07215A" class="close" @click="toggleOverlay"/>
        <h3>Assegnazione effettuata correttamente.</h3>
        <v-icon name="bi-check-circle-fill" scale="5" fill="#fff" />
        <div class="mt-8 inline-block w-full">
          <button @click="toggleOverlay">Chiudi e continua</button>
        </div>
      </div>
    </Form>

    <div class="container" v-else>
      LOADING ...
    </div>

    <!-- SCANSIONI PASSATE -->
    <section class="past-orders text-center" v-if="loading">
      <v-icon name="fa-spinner" class="animate-spin text-gray-400 w-20" />
    </section>
    <section class="past-orders" v-else>
      <div class="container mx-auto sm:flex sm:flex-wrap" v-if="!error">
        <div class="item">
          <h1 class="page-title">—Storico Scansioni</h1>
          <h3 class="subtitle" v-if="userData && userData.email">
            Utente: {{userData.first_name}} {{ userData.last_name }}
            <span v-if="userData.first_name || userData.last_name">, </span>{{ userData.email }}
          </h3>
          <div class="filter-wrapper">
            <div class="bg-primary-lblue bg-opacity-50 rounded-xl px-2 py-4">
              <small>Filtra la lista per codice carnet:</small>
              <Form
                @submit="filterByCarnet"
                v-if="!resetForms2"
              >
                <Field
                  name="carnetFilter"
                  rules="required|min:7|max:7"
                  placeholder="XXXXXXX"
                  class="w-full py-1 px-2"
                  v-model="carnetFilter"
                />
                <ErrorMessage name="carnetFilter" class="text-xs text-red-600"/>
                <button class="btn w-full bg-primary-blue text-white mt-4 pt-2" type="submit">
                  FILTRA
                </button>
              </Form>
              <span class="reset mt-4 block" v-if="isFiltered">
                <button class="btn reset w-full" @click="filterPastReset">Resetta il filtro</button>
              </span>
            </div>
          </div>
        </div>
        <div class="item large sm:pl-10">
          <ul class="scan-history" v-if="getScans && getScans.length > 0">
            <li v-for="(scan, index) in getScans" :key="index">
              <article>
                <div class="heading">
                  <h5 v-if="scan?.assignable?.code">#{{ scan.assignable.code }}</h5>
                  <time v-if="scan.created_at">{{ timeFormatter(scan.created_at) }}</time>
                </div>
                <div class="content">
                  <div class="field" v-if="getScanValue(scan)">
                    <label>Tipo di carnet:</label><span>{{ getScanValue(scan) }}</span>
                  </div>
                  <div class="field" v-if="scan?.assignee?.code">
                    <label>Assegnato a:</label><span>{{ scan.assignee.code }}</span>
                  </div>
                  <div class="field" v-if="scan?.assigner?.code">
                    <label>Assegnato da:</label><span class="truncate" :title="scan.assigner.code">
                      {{ scan.assigner.code }}
                    </span>
                  </div>
                </div>
              </article>
            </li>
          </ul>
          <div v-else>
            <p class="mt-10 px-2 py-1 text-center text-primary-lblue">
              ⚠️ Al momento non ci sono scansioni passate da visualizzare ⚠️
            </p>
          </div>
          <div class="load-more text-center mt-10" v-if="getScans && getScans.length > 0 && getScans.length >= counter * limit && !isFiltered">
            <button class="btn submit bg-primary-lblue text-white pt-2" @click="loadMore">
              Carica altri
            </button>
          </div>
        </div>
      </div>
    </section>
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout <b v-if="userData">{{userData.email}}</b>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import i18n from '@/utils/i18n'
// import ops from '@/json/mock/operators.json'
// import scan from '@/json/mock/scanner.json'
import { useRoute } from 'vue-router'
import { Field, Form, ErrorMessage } from 'vee-validate'
// import AdvancedSearch from '@/components/AdvancedSearch.vue'
import moment from 'moment'
moment.locale('it')
// i18n.global.locale.value = 'it'

export default {
  components: {
    // AdvancedSearch,
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const query = useRoute().query
    return {
      query
    }
  },
  data () {
    return {
      operators: null,
      scanHistory: [],
      loading: true,
      carnet: null,
      operator: null,
      overlayOpen: false,
      counter: 0,
      limit: 30,
      error: false,
      carnetFilter: null,
      isFiltered: false,
      resetForms: false,
      resetForms2: false
    }
  },
  computed: {
    ...mapGetters([
      'getCarnet'
    ]),
    userData () {
      return this.$store.state.userData
    },
    extraData () {
      return this.$store.state.userData ? JSON.parse(this.$store.state.userData.extra_data) : false
    },
    shownFields () {
      if (this.extraData && this.extraData.shown_fields === undefined) return []
      else return this.extraData.shown_fields
    },
    lockedFields () {
      if (this.extraData && this.extraData.locked_fields === undefined) return []
      else return this.extraData.locked_fields
    },
    requiredFields () {
      if (this.extraData && this.extraData.required_fields === undefined) return []
      else return this.extraData.required_fields
    },
    getScans () {
      return this.scanHistory
    }
  },
  async mounted () {
    // if (ops) this.operators = ops
    // if (scan) this.scanHistory = scan
    if (localStorage.getItem('carnet_' + process.env.VUE_APP_NAME)) this.carnet = JSON.parse(localStorage.getItem('carnet_' + process.env.VUE_APP_NAME))
    else if (this.getCarnet) this.carnet = this.getCarnet
    if (this.query.id) this.carnet = this.query.id
    console.log('LOADING')
    await this.getCarnetHistory()
    this.loading = false
    setTimeout(() => {
      if (this.carnet) document.getElementById('operatorInput').focus()
    }, 100)
  },
  methods: {
    checkRegex () {
      console.log('CARNET', this.carnet)
      if (this.carnet.includes('https://coopfi.promotica.it/block?id=')) {
        const sub = this.carnet.split('=')[1]
        this.carnet = sub
      }
    },
    async filterPastReset () {
      this.scanHistory = []
      this.carnetFilter = null
      this.isFiltered = false
      this.resetForms2 = true
      setTimeout(() => {
        this.resetForms2 = false
      }, 100)
      await this.getCarnetHistory()
    },
    async filterByCarnet (values) {
      this.counter = 0
      const data = {
        carnet: values.carnetFilter
      }
      await this.$store.dispatch('filterByCarnet', data)
        .then((rsp) => {
          if (rsp && rsp.data) {
            this.scanHistory = []
            this.scanHistory.push(rsp.data)
            this.isFiltered = true
          } else {
            const msg = 'Codice carnet inesistente o non ancora assegnato.'
            const error = true
            this.emitter.emit('fireAlert', { msg, error })
          }
        })
    },
    getScanValue (scan) {
      if (scan?.assignable?.extra_data) {
        const content = JSON.parse(scan?.assignable?.extra_data)
        if (content?.kind) {
          return content.kind
        }
      }
      return false
    },
    async loadMore () {
      this.counter++
      await this.getCarnetHistory()
    },
    toggleOverlay () {
      this.overlayOpen = !this.overlayOpen
      this.carnet = null
      this.operator = null
      this.resetForms = true
      setTimeout(() => {
        this.resetForms = false
      }, 100)
      setTimeout(() => {
        document.getElementById('carnetInput').focus()
      }, 200)
    },
    selectOperator (val) {
      this.operator = val
    },
    async getCarnetHistory () {
      const data = {
        skip: this.counter * this.limit,
        limit: (this.counter * this.limit) + this.limit
      }
      await this.$store.dispatch('carnetHistory', data)
        .then((rsp) => {
          if (rsp && rsp.data) {
            Array.prototype.push.apply(this.scanHistory, rsp.data)
          } else {
            this.error = true
          }
        })
    },
    async tryCarnet (values) {
      const data = {
        assignable_code: values.carnet,
        assignee_code: values.operator
      }
      const assignment = await this.$store.dispatch('assignCarnet', data)
      if (!assignment.response) {
        console.log(assignment)
        const msg = 'Assegnazione andata a buon fine.'
        const error = false
        this.emitter.emit('fireAlert', { msg, error })
        this.overlayOpen = true
        this.$store.commit('setCarnet', null)
        this.counter = 0
        this.scanHistory = []
        await this.getCarnetHistory()
      } else {
        let msg = 'Assegnazione NON corretta: ricontrollare entrambi i codici inseriti.'
        if (assignment?.response?.data?.detail[0]) {
          msg = assignment.response.data.detail.reduce((a, v) => {
            if (v.msg === 'Assignable has already been assigned.') v.msg = 'Codice Carnet già assegnato precedentemente'
            if (v.msg === 'Assignable does not exist in the system.') v.msg = 'Codice Carnet inesistente'
            return a + v.msg + '<br/>'
          }, '')
        }
        const error = true
        this.emitter.emit('fireAlert', { msg, error })
      }
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    },
    refresh (timer) {
      setTimeout(() => {
        this.$router.go()
      }, timer)
    },
    timeFormatter (time) {
      return moment(time).format('ll')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/views/carnet.scss";
</style>
